// react libs
import React, {useState, useContext, useRef, useEffect} from 'react';

// 3rd party react libs
import { withRouter } from "react-router-dom";
import { Dropdown, Modal } from 'react-bootstrap';

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import DocumentBrowser from './DocumentBrowser';
import UserProfile from './UserProfile';

// melodisto helper modules
import { DEBUG, projectAuth, isValidEmail, anaylzeBrowser, MAX_RECORDING_LENGTH, USER_TYPES } from '../firebase/config';
import userPhoto from '../res/user-photo.svg';
import openDocIcon from '../res/open-doc.svg';
import sliders from '../res/sliders.svg';
import piano from '../res/piano.svg';
// import metronome from '../res/metronome.svg';
// import tuner from '../res/tuner.svg';

// melodisto styles
import styles from './Controls.module.css';

function Controls(props) {
  DEBUG.COMP_RENDER && console.log('<Controls>');

  const [userContext] = useContext(UserContext);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [recordingClicked, setRecordingClicked] = useState(false);
  const currentBrowser = useRef(anaylzeBrowser());

  const photoURL = userContext?.photoUrl ? userContext.photoUrl : userPhoto;

  useEffect(() => {
    if (!props.isRecording) {
      setRecordingClicked(false);
      props.setRecordingClicked(false);
    }
  }, [props.isRecording])

  const doEndLesson = () => {
    setTimeout(() => {
      props.history.push('/');
    }, 700);
  }

  const doSignOut = () => {
    projectAuth.signOut();
  }

  const doShowUserProfileModal = () => {
    setShowUserProfileModal(true);
  }

  const doHideUserProfileModal = () => {
    setShowUserProfileModal(false);
  }

  const getRoomId = () => {
    const lastUrlPart = window.location.href.split('/')[window.location.href.split('/').length - 1];
    if (isValidEmail(lastUrlPart)) return lastUrlPart;
    return '';
  }

  const isUserHost = () => {
    return getRoomId() === userContext.email;
  }

  const toggleRecorder = () => {
    props.toggleRecorder(Date.now());
    setRecordingClicked(!recordingClicked);
    props.setRecordingClicked(!recordingClicked);
  }

  return (
    <div className={styles.controlsContainer}>

      {!props.isLoggedIn && !props.isLessonScreenMounted &&
        <button className={styles.controlButton} title="Remote Piano" onClick={() => props.history.push('/signin')}>
          Log In
        </button>
      }

      {props.isLoggedIn && 
        <div>
          {userContext.email &&
            <div className="buttonGroup">

              {/* 
              <button className={styles.controlButton} title="Metronome" onClick={() => alert("Not enabled in this edition.")}>
                <img src={metronome} alt="Metronome" />
              </button>
              <button className={styles.controlButton} title="Tuner" onClick={() => alert("Not enabled in this edition.")}>
                <img src={tuner} alt="Tuner" />
              </button>
              */}
            </div>
          }

          <button className={styles.controlButton} title="Remote Piano" onClick={() => props.setPianoData({...props.pianoData, sender: userContext.email, show: true})}>
            <img src={piano} alt="Remote Piano" />
          </button>

          {userContext.email && 
            <DocumentBrowserModal
              shareDocument={props.shareDocument}
              openDocument={props.openDocument}
            />
          }
          
          {(props.connectionStatus === 'connected') && (isUserHost()) &&
            <button className={styles.controlButton} title="Remote Audio Settings" onClick={props.doShowAudioOptionsModal}>
              <img src={sliders} alt="Remote Audio Settings" />
            </button>
          }

          {!currentBrowser.current.isSafari && userContext.email && !(props.isLessonScreenMounted && props.connectionStatus !== 'connected') &&
            <button className={styles.controlButton} onClick={toggleRecorder} title={"Record yourself (" + MAX_RECORDING_LENGTH/1000 + " seconds max.)"} disabled={props.isRecording !== recordingClicked}>
              {!props.isRecording && !recordingClicked && <div><span className={styles.recordBullet}/>REC</div>}
              {!props.isRecording &&  recordingClicked && <div><span className={styles.stopBullet}/>STOP</div>}
              { props.isRecording && <div><span className={styles.stopBulletBlink}/>STOP</div>}
            </button>
          }

          {currentBrowser.current.isSafari && userContext.email && 
            <button className={styles.controlButton} onClick={() => alert("Sorry, but recording doesn't work with Safari.\nUse Chrome, Edge or Forefox instead.")} title={"Record yourself (" + MAX_RECORDING_LENGTH/1000 + " seconds max.)"} disabled={props.isRecording !== recordingClicked}>
              {!props.isRecording && !recordingClicked && <div><span className={styles.recordBullet}/>REC</div>}
            </button>
          }
        </div>
      }

      {(props.isLessonScreenMounted) && <EndLesson endLessonHandler={doEndLesson} isDisabled={recordingClicked} setRemoteEndLessonTimeStamp={props.setRemoteEndLessonTimeStamp} connectionStatus={props.connectionStatus}/>}

      {props.isLoggedIn && 
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
            <img className={styles.avatarImage} src={photoURL} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {userContext?.myProfile && <Dropdown.Item onClick={doShowUserProfileModal}>My Profile...</Dropdown.Item>}
            {/* <Dropdown.Item onClick={doStartLesson}>Host a Session</Dropdown.Item> */}
            <Dropdown.Divider />
            <Dropdown.Item onClick={doSignOut}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      }
  
      <UserProfileModal show={showUserProfileModal} doHideUserProfileModal={doHideUserProfileModal}/>
 
      </div>
    )
}

function UserProfileModal(props) {

  return (
    <Modal className="melodistoModal" centered show={props.show} onHide={props.doHideUserProfileModal} dialogClassName="largeModal" size="xl" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>My Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserProfile/>
      </Modal.Body>
    </Modal>
  )
}

function EndLesson(props) {
  const [userContext] = useContext(UserContext);

  // teachers
  if (props.connectionStatus === 'connected' && userContext?.type === USER_TYPES.TEACHER) {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm" style={{backgroundColor: '#bd0b0b', fontWeight: 'bold'}}>
          End&nbsp;&nbsp;
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => props.setRemoteEndLessonTimeStamp(Date.now())}>
            Dismiss student, but stay in the call
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={props.endLessonHandler}>
            End this call and return to home screen
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )

  // students
  } else {
    return (
      <div title="Leave this call">
        <button className={styles.endButton} onClick={props.endLessonHandler} disabled={props.isDisabled}>End</button>
      </div>
    )
  }
}

function DocumentBrowserModal(props) {
  const [show, setShow] = useState(false);

  const closeDocumentBrowserModal = () => {
    setShow(false);
  }

  const openDocumentBrowserModal = () => {
    setShow(true);
  }

  const openDocument = (doc) => {
    props.openDocument(doc);
    closeDocumentBrowserModal();
  }

  return (
    <>
      <button className={styles.controlButton} onClick={openDocumentBrowserModal} title="Open Sheet Music">
        <img src={openDocIcon} alt="Open Sheet Music"/>
      </button>

      <Modal className="melodistoModal" centered show={show} onHide={closeDocumentBrowserModal} dialogClassName="maxHeightModal" size="xl" animation={false}>
        <Modal.Header closeButton style={{cursor: 'default'}}>
          <Modal.Title>Open Sheet Music</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocumentBrowser
            asModal={true}
            shareDocument={props.shareDocument}
            openDocument={openDocument}
          />
        </Modal.Body>
      </Modal>
    </>

  )
}

export default withRouter(Controls);
