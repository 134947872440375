// react libs
import React, { useState, useContext, useEffect, useRef } from 'react';

// 3rd party react libs
import { useHistory } from "react-router-dom";

// 3rd party libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Cookies from 'universal-cookie';

// melodisto components
import { UserContext } from "../App";
import { MusicStandContext } from "../App";
import Activities from './Activities';
import DocumentBrowser from './DocumentBrowser';
import DocumentViewer from './DocumentViewer';
import VideoRecorder from './VideoRecorder';

// melodisto helper modules
import { DEBUG, projectAuth, projectFirestore, USER_TYPES, isValidEmail, ANONYMOUS_EMAIL } from '../firebase/config';
import womanAtDesktop from '../res/woman-at-desktop.png';
import manAtDesktop from '../res/man-at-desktop.png';

// melodisto styles
import styles from './HomeScreen.module.css';

function HomeScreen(props) {
  DEBUG.COMP_RENDER && console.log('<HomeScreen>');

  const [userContext] = useContext(UserContext);
  const history = useHistory();
  const [hostEmail, setHostEmail] = useState('');
  const [musicStand] = useContext(MusicStandContext);
  const [browerRefreshTimeStamp, setBrowserRefreshTimeStamp] = useState(0);
  const [isRecorderVisible, setIsRecorderVisible] = useState(false);
  const cookies = useRef();

  useEffect(() => {
    cookies.current = new Cookies();
  },[]);

  useEffect(() => {

    // legacy: populate the user's display name
    if (projectAuth.currentUser && !userContext?.displayName) {
      projectFirestore.collection('users').doc(userContext.email).set({
        displayName: projectAuth.currentUser.displayName,
        uid: projectAuth.currentUser.uid,
        photoUrl: projectAuth.currentUser.photoURL,
      }, {merge: true});
    }

    // prepolulate the text input with the most recent teacher ID
    if (userContext.myStudents && !!Object.keys(userContext.myStudents)) {
      setHostEmail(Object.keys(userContext.myStudents)[0]);
    } else {
      setHostEmail(cookies.current.get('myTeacher'));
    }

    setBrowserRefreshTimeStamp(Date.now());

  },[userContext]);

  const doStartLesson = async (e) => {
    e.preventDefault();
    history.push(userContext.email);
  }

  const doJoinLesson = async (e) => {
    e.preventDefault();

    if (hostEmail === userContext.email) {
      alert("Looks like you tried calling yourself. Please don't do that.");
      return;
    }

    if (!isValidEmail(hostEmail)) {
      alert("Enter a valid email address");
      return;
    }

    cookies.current.set('myTeacher', hostEmail.toLowerCase(), { path: '/' });

    history.push(hostEmail.toLowerCase());
  }

  return (
    <div className="mainScreen fadein" style={props.isLoggedIn ? {} : {display: 'flex', justifyContent: 'center'}}>

      {musicStand ? (
        <div>
          <DocumentViewer
            shareDocument={props.shareDocument}
          />
        </div>
      ) : (
        <div className={styles.homeBoxes}>

          <div className="d-flex">
            {props.isLoggedIn && [USER_TYPES.TEACHER, USER_TYPES.THERAPIST].includes(userContext?.type) ? ( 
              <div className="melodistoCard singleUnit">
                <div className="melodistoCardHeader">Hello {userContext.displayName}</div>
                <div className="melodistoCardBody">
                  <div className="mt-2">Students can reach you under</div>
                  <div className="mt-1 mb-3">https://app.melodisto.com/{userContext.email}</div>
                  <button className={styles.startButton} onClick={doStartLesson}>Start a Lesson</button>
                </div>
                <div className="melodistoCardFooterVisual">
                  <img className={styles.visual} src={womanAtDesktop} alt=""/>
                  <MediaSettingsButton doShowMediaSettingsModal={props.doShowMediaSettingsModal}/>
                </div>
              </div>
            ) : (
              <div className="melodistoCard singleUnit">
                <div className="melodistoCardHeader">Hello {userContext.displayName ? userContext.displayName : "Student"}</div>
                <div className="melodistoCardBody">
                  <form className={styles.messageForm} onSubmit={doJoinLesson}>
                    <div className={styles.calleContainer}>
                      <div className='mt-3'>
                        <input list="myStudents"
                          className={styles.calleeName}
                          value={hostEmail}
                          onChange={(e) => setHostEmail(e.target.value.trim())}
                          placeholder="Your teacher's studio ID or email"
                        />
                        {userContext?.myStudents && 
                          <datalist id="myStudents">
                            {Object.keys(userContext.myStudents).map((key) => {
                              if (key !== ANONYMOUS_EMAIL) {
                                return (
                                  <option key={key} value={key}>{userContext.myStudents[key]}</option>                              
                                )
                              }
                            })
                            }
                          </datalist>
                        }
                      </div>
                      <div className="mt-2">
                        <button className={styles.joinButton} onClick={doJoinLesson}>Call Your Teacher</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="melodistoCardFooterVisual">
                  <img className={styles.visual} src={womanAtDesktop} alt=""/>
                  <MediaSettingsButton doShowMediaSettingsModal={props.doShowMediaSettingsModal}/>
                </div>
              </div>
            )}

            
            {props.isLoggedIn && 
              <div className="melodistoCard singleUnit optional">
                <div className="melodistoCardHeader">Recommended for You</div>
                <div className={`melodistoCardBody ${styles.recommendedContainer}`}>
                  <div className={styles.recommended}>
                    <ul>
                      <li>
                        <a href="https://www.youtube.com/watch?v=jSOU-J9KHbg" target="_blank" rel="noreferrer">
                          How to read notes fast
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/watch?v=re4Rx0j2eMI" target="_blank" rel="noreferrer">
                          Basics of Music Theory: Part I
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/watch?v=_UxzDjU3-hM" target="_blank" rel="noreferrer">
                          The Circle of Fifths made clear
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="melodistoCardFooterVisual">
                  <img className={styles.visual} src={manAtDesktop} alt=""/>
                </div>
              </div>
            }
          </div>

          {props.isLoggedIn &&
            <div className="melodistoCard fullUnit">
              <DocumentBrowser
                shareDocument={props.shareDocument}
                browerRefreshTimeStamp={browerRefreshTimeStamp}
                openDocument={props.openDocument}
              />
            </div>
          }

        </div>
      )}

      {props.isLoggedIn &&
        <div className={styles.sideBar}>

          <div>
            <VideoRecorder
              setIsRecording={props.setIsRecording}
              setIsRecorderVisible={setIsRecorderVisible}
              mediaTrackConstraints={props.mediaTrackConstraints}
              recorderTimeStamp={props.recorderTimeStamp}
              setBrowserRefreshTimeStamp={setBrowserRefreshTimeStamp}
            />
          </div>

          <div className="melodistoCardHeader">My Activities</div>
          <div className="melodistoCard" style={{borderRadius: '0 0 8px 8px'}}>
            {props.isLoggedIn && 
              <Activities
                openDocument={props.openDocument}
                browerRefreshTimeStamp={browerRefreshTimeStamp}
              />
            }
          </div>
        
        </div>
      }

    </div>
  )
}

function MediaSettingsButton(props) {
  return (
    <button className={`${styles.mediaSettingsButton}`} onClick={props.doShowMediaSettingsModal}>
      <span className="mr-2">Audio/Video Settings</span>
      <FontAwesomeIcon className="fa-button" icon="cog"/>
    </button>
  )
}


export default HomeScreen;